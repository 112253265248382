import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; 

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateTimePickerComponentProps {
  label: string;
  value: string;
  onChange: (value: Dayjs | null) => void;
  defaultValue: Dayjs;
}

const DateTimePickerComponent: React.FC<DateTimePickerComponentProps> = ({ label, value, onChange, defaultValue }) => {
  const handleChange = (date: Dayjs | null) => {
    if (date) {
      // Convert the selected date to UTC before calling onChange
      onChange(date.tz("UTC"));
    } else {
      onChange(null);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>
      <DateTimePicker
        className='w-full'
        defaultValue={defaultValue}
        value={value ? dayjs(value).tz("America/Toronto") : null}
        onChange={handleChange}
        format='dddd, MMMM D, YYYY h:mm A'
      />
    </div>
  );
};

export default DateTimePickerComponent;