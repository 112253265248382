import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import BunnyForm from '../components/Forms/BunnyForm';
import KibanaLinkButton from '../components/KibanaDashboardLink';
import BunnyCsvForm from '../components/Forms/BunnyCsvForm';

const getMetadataValue = (metadata: { key: string; value: string }[], key: string): string | undefined => {
  const item = metadata.find(meta => meta.key === key);
  return item ? item.value : undefined;
};

export interface FormData {
  from: string;
  to: string;
  vehicle: string[];
  filterBy: string;
  generateCsvs: boolean;
}

const Bunny: React.FC = () => {
  const now = dayjs().tz("America/Toronto");
  const midnight = now.startOf('day');

  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState<FormData>({
    from: midnight.toISOString(),
    to: now.toISOString(),
    vehicle: ['All'],
    filterBy: 'All',
    generateCsvs: false,
  });

  const [formDataCsv, setFormDataCsv] = useState<FormData>({
    from: midnight.toISOString(),
    to: now.toISOString(),
    vehicle: ['All'],
    filterBy: 'All',
    generateCsvs: false,
  });

  return (
    <div className="p-4 flex flex-row h-full w-full space-x-4">
	  <div className='flex flex-col h-fit w-fit space-y-4'>
		<span className='w-full h-full self-center text-center font-bold'>
			Generate Route Visualization
		</span>
      <BunnyForm isLoading={isLoading} setIsLoading={setIsLoading} 
        vehicles={['Pilot2_BFLG22', 'Pilot2_BFLG24', 'Pilot2_JEIL929', 'Pilot2_BFLG40', 
          'Pilot2_BFLG38', 'Pilot2_BFLG41', 'Pilot2_BFLG29', 'Pilot2_BFLG39', 
          'Pilot2_JEIL366', 'Pilot2_JEIL929', 'Pilot2_JEIL948', 'Pilot2_JEIL946',
        'Pilot2_JEIL707', 'Pilot2_JEIL243']} 
      setFormData={setFormData} formData={formData}/>
	  </div>
	  <div className='flex flex-col h-fit w-fit space-y-4'>
		<span className='w-full h-full self-center text-center font-bold'>
			Generate Route CSV
		</span>
	  <BunnyCsvForm isLoading={isLoading} setIsLoading={setIsLoading} 
        vehicles={['Pilot2_BFLG22', 'Pilot2_BFLG24', 'Pilot2_JEIL929', 'Pilot2_BFLG40', 
          'Pilot2_BFLG38', 'Pilot2_BFLG41', 'Pilot2_BFLG29', 'Pilot2_BFLG39', 
          'Pilot2_JEIL366', 'Pilot2_JEIL929', 'Pilot2_JEIL948', 'Pilot2_JEIL946',
        'Pilot2_JEIL707', 'Pilot2_JEIL243']} 
	  setFormData={setFormDataCsv} formData={formDataCsv}/>
		</div>
      <KibanaLinkButton />
    </div>
  );
};

export default Bunny;