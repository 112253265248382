import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import MetadataInfo from '../components/Video/MetadataInfo';
import VideoPlayerHandler from '../components/Video/VideoPlayerHandler';
import LogWindow from '../components/Logs/LogWindow';
import VideoActions from '../components/Video/VideoActions';
import CommentSection from '../components/Video/CommentSection';
import { Metadata, defaultMetadata } from '../types';

// Extend dayjs with necessary plugins
dayjs.extend(duration);
dayjs.extend(customParseFormat);

const VideoPlayer: React.FC = () => {
  const navigate = useNavigate()
  const { videoId } = useParams<{ videoId: string }>();
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [metadata, setMetadata] = useState<Metadata>(defaultMetadata);
  const [logMessages, setLogMessages] = useState<{ ip: string; msgBody: string; timestamp: string }[]>([]);
  const [newComment, setNewComment] = useState('');
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
  const [logsFetched, setLogsFetched] = useState(false);  // Prevent multiple log fetches

  const urlParams = new URLSearchParams(window.location.search);
  const startTime = parseInt(urlParams.get('time') || '0', 10);

  const prevMetadataRef = useRef<Metadata>(defaultMetadata);

  const calculateEndTime = (startTime: string, videoLength: string): string => {
    const startDate = dayjs(startTime);

    if (!startDate.isValid()) {
      throw new Error(`Invalid start time: ${startTime}`);
    }

    const [minutes, seconds] = videoLength.split(':').map(Number);
    const milliseconds = Math.floor((seconds % 1) * 1000);

    const endDate = startDate
      .add(minutes, 'minute')
      .add(Math.floor(seconds), 'second')
      .add(milliseconds, 'millisecond');

    return endDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };

  useEffect(() => {
    const fetchVideoLogs = async () => {
      if (logsFetched) return; // Prevent refetching logs
      setIsLoadingLogs(true);
      try {
        let startTime = metadata.start_time;

        if (!startTime) {
          throw new Error(`Invalid start time format: ${startTime}`);
        }

        const parsedStartTime = dayjs(startTime, 'MM-DD-YYYYTHH:mm:ss', true);
        if (!parsedStartTime.isValid()) {
          throw new Error(`Invalid start time format: ${metadata.start_time}`);
        }

        startTime = parsedStartTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        const url = `${process.env.REACT_APP_BACKEND_URL}/fetch-video-logs`;
        const endTime = calculateEndTime(startTime, '10:00');

        const response = await axios.post(url, { from: startTime, to: endTime, vehicle: metadata.device_id }, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
        });

        const sortedMessages = response.data.logs
          .map((hit: any) => ({
            ip: hit._source.ip,
            msgBody: hit._source.msgBody,
            timestamp: hit._source['ts'],
          }))
          .sort((a: any, b: any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

        setLogMessages(sortedMessages);
        setLogsFetched(true); // Mark logs as fetched
      } catch (error) {
        console.error('Error fetching video logs', error);
      } finally {
        setIsLoadingLogs(false);
      }
    };

    // Only fetch logs if metadata has changed and logs have not already been fetched
    if (metadata.start_time && !logsFetched) {
      fetchVideoLogs();
    }
  }, [metadata, logsFetched]);

  useEffect(() => {
    const fetchVideoInfo = async () => {
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/get-video-info`;
        const response = await axios.post(url, { videoId }, 
          { headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
        });
        setMetadata(response.data);
      } catch (error) {
        if(axios.isAxiosError(error)){
          if(error.response && error.response.status == 401){
            alert('Session has expired, please login again.')
            navigate('/login')
          }
        }
        console.error('Error fetching device statuses', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoInfo();
  }, [videoId]);

  // Handle updating metadata
  const handleUpdateVideoMetadata = async (metadataType: string) => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/update-video-info`;
      const newValue = metadata[metadataType.toLowerCase() as keyof Metadata] === 'True' ? 'False' : 'True';

      await axios.post(url, { videoId, metadata: { [metadataType.toLowerCase()]: newValue } }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
      });

      setMetadata((prevMetadata) => ({
        ...prevMetadata,
        [metadataType.toLowerCase()]: newValue,
      }));
    } catch (error) {
      if(axios.isAxiosError(error)){
        if(error.response && error.response.status == 401){
          alert('Session has expired, please login again.')
          navigate('/login')
        }
      }
      console.error('Error updating video metadata', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle adding a comment
  const handleAddComment = async (comment: string) => {
    setIsLoading(true);
    setNewComment('');
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/update-video-info`;
      const currentCommentList = metadata.commentList ? JSON.parse(metadata.commentList) : [];
      const newCommentList = currentCommentList.concat([comment]);

      await axios.post(url, { videoId, metadata: { commentList: JSON.stringify(newCommentList) } }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
      });

      setMetadata((prevMetadata) => ({
        ...prevMetadata,
        commentList: JSON.stringify(newCommentList),
      }));
    } catch (error) {
      if(axios.isAxiosError(error)){
        if(error.response && error.response.status == 401){
          alert('Session has expired, please login again.')
          navigate('/login')
        }
      }
      console.error('Error adding comment', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle deleting a comment
  const handleDeleteComment = async (indexToDelete: number) => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/update-video-info`;
      const currentCommentList = JSON.parse(metadata.commentList);
      const newCommentList = currentCommentList.filter((_: any, index: number) => index !== indexToDelete);

      await axios.post(url, { videoId, metadata: { commentList: JSON.stringify(newCommentList) } }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
      });

      setMetadata((prevMetadata) => ({
        ...prevMetadata,
        commentList: JSON.stringify(newCommentList),
      }));
    } catch (error) {
      if(axios.isAxiosError(error)){
        if(error.response && error.response.status == 401){
          alert('Session has expired, please login again.')
          navigate('/login')
        }
      }
      console.error('Error deleting comment', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle time updates from the video player
  const handleTimeUpdate = (time: number) => {
    setCurrentTimeInSeconds(time);
  };

  return (
    <div className="flex flex-col items-center justify-start bg-gray-100 p-6 rounded-lg shadow-lg w-full h-full">
      <div className="flex flex-row justify-center space-x-2 w-full">
        <MetadataInfo
          metadata={metadata}
          updateVideoMetadata={handleUpdateVideoMetadata}
          isLoading={isLoading}
        />
        <VideoPlayerHandler
          videoId={videoId!}
          startTime={startTime}
          handleTimeUpdate={handleTimeUpdate}
        />
        <LogWindow
          isLoading={isLoadingLogs}
          logMessages={logMessages}
          currentTimeInSeconds={currentTimeInSeconds}
          videoStartTime={metadata.start_time}
        />
      </div>
      <div className="w-full flex flex-row justify-between space-x-2 mx-2">
        <VideoActions
          metadata={metadata}
          videoId={videoId!}
          copied={copied}
          setCopied={setCopied}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          currentTime={currentTimeInSeconds}
        />
        <CommentSection
          newComment={newComment}
          setNewComment={setNewComment}
          isLoading={isLoading}
          handleDeleteComment={handleDeleteComment}
          addComment={handleAddComment}
          comments={metadata.commentList}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
