import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectDropdownProps {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: string[];
  title: string;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({ value, onChange, options, title }) => {
  return (
    <div className="mb-4">
      <InputLabel id="multiple-select-label">{title}</InputLabel>
      <Select
        className='w-full'
        labelId="multiple-select-label"
        value={value}
        onChange={onChange}
        label="Vehicle"
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectDropdown;