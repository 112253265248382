import React from 'react';

interface CSVDownloaderProps {
  videos: any[];
}

const getMetadataValue = (metadata: { key: string; value: string }[], key: string): string | undefined => {
  const item = metadata.find(meta => meta.key === key);
  return item ? item.value : undefined;
};

const generateCSV = (videos: any[]) => {
  const headers = ['Device_id', 'Timestamp', 'Liked', 'Marked for Review', 'Marked for Fleetedge', 'Link'];
  const rows = videos.map(video => [
    getMetadataValue(video.metadata, 'device_id') || '',
    getMetadataValue(video.metadata, 'start_time') || '',
    getMetadataValue(video.metadata, 'liked') || 'False',
    getMetadataValue(video.metadata, 'review') || 'False',
    getMetadataValue(video.metadata, 'fleetedge') || 'False',
    `${window.location.origin}/video/${video.videoId}`
  ]);

  const csvContent =
    'data:text/csv;charset=utf-8,' +
    [headers, ...rows]
      .map(row => row.map(item => `"${item}"`).join(','))
      .join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'video.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CSVDownloader: React.FC<CSVDownloaderProps> = ({ videos }) => {
  return (
    <button
      onClick={() => generateCSV(videos)}
      className="px-3 py-1 bg-blue-500 hover:bg-green-700 text-white rounded-md"
    >
      Download CSV
    </button>
  );
};

export default CSVDownloader;