import React from 'react';
import axios from 'axios';
import Spinner from '../Spinner';

const downloadVideo = async (videoId: string, vehicle: string, startTime: string) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/download-video`;
  const response = await axios.post(
    url,
    { videoId: videoId },
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true, // Ensure cookies (including the JWT) are sent with the request
    }
  );
  const blob = new Blob([response.data], { type: 'video/mp4' });
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = `${vehicle}_${startTime}.mp4`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface DownloadButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  videoId: string;
  isLoading: boolean;
  vehicle: string;
  startTime: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ videoId, setIsLoading, isLoading, vehicle, startTime }) => {
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      await downloadVideo(videoId, vehicle, startTime);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading video:', error);
      setIsLoading(false);
    }
  };

  return (
    <button
      disabled={isLoading}
      className={`text-xs relative font-bold py-2 px-4 rounded-md transition-all ease-in-out duration-300
        ${isLoading ? 'bg-gray-400 text-gray-800 cursor-not-allowed' : 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 shadow-lg'}`}
      onClick={handleDownload}
    >
      {isLoading ? (
        <span className="flex items-center justify-center">
          <Spinner size={20} color="white" /> {/* Using Spinner component here */}
          <span className='ml-2'>Loading...</span>
        </span>
      ) : (
        'Download'
      )}
    </button>
  );
};

export default DownloadButton;
