import React from 'react';

const DashboardHome: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-full bg-gray-100">
      {/* Logo */}
      <div className="mb-8">
        <img
          src="/android-chrome-512x512.png"
          alt="VAPR Logo"
          className="h-24 w-24"
        />
      </div>

      {/* Main text */}
      <div className="text-center space-y-4">
        <h1 className="text-4xl font-bold text-gray-800">VAPR Dashboard</h1>
        <p className="text-lg text-gray-600">
          Use this portal to access live data from the VAPR fleet.
        </p>
      </div>

      {/* Example of additional section for other content */}
      <div className="mt-12">
        <p className="text-center text-gray-500">
          For more information or support, email {' '}
          <a href="mailto:support@vapr.com" className="text-blue-500 underline">
            cmay@productinsight.com
          </a>.
        </p>
      </div>
    </div>
  );
};

export default DashboardHome;
