// src/types.ts

export interface Metadata {
    device_id: string;
    file_size: string;
    finished_recording: string;
    meta_file: string;
    output_file: string;
    presence: string;
    should_upload: string;
    start_time: string;
    upload_time: string;
    uploaded: string;
    video_length: string;
    video_uuid: string;
    liked?: string;
    review?: string;
    fleetedge?: string;
    commentList: string;
  }
  
  export const defaultMetadata: Metadata = {
    device_id: '',
    file_size: '',
    finished_recording: '',
    meta_file: '',
    output_file: '',
    presence: '',
    should_upload: '',
    start_time: '',
    upload_time: '',
    uploaded: '',
    video_length: '',
    video_uuid: '',
    liked: 'False',
    review: 'False',
    fleetedge: 'False',
    commentList: '[]',
  };
  