import React from 'react';
import { Metadata } from '../../types';

interface MetadataInfoProps {
  metadata: Metadata;
  updateVideoMetadata: (metadataType: string) => void;
  isLoading: boolean;
}

const MetadataInfo: React.FC<MetadataInfoProps> = ({ metadata, updateVideoMetadata, isLoading }) => (
  <div className="max-w-[300px] min-h-full bg-gray-800 p-4 flex flex-col border-2 border-black">
    <div className="flex-grow flex flex-col justify-between space-y-2 text-xs text-white">
      <div className="flex-grow overflow-hidden">
        <div className="flex flex-col justify-start w-fit h-full space-y-3">
          {Object.entries(metadata).map(([key, value]) => (
            key !== 'commentList' && key !== 'liked' && key !== 'review' && key !== 'fleetedge' && (
              <span key={key} className="truncate">
                <span className="font-bold">{key.replace('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase())}:</span> 
                <span className="whitespace-nowrap overflow-hidden text-ellipsis ml-1">{value}</span>
              </span>
            )
          ))}
          <span className="truncate"><span className="font-bold">Liked:</span> {metadata.liked || 'False'}</span>
          <span className="truncate"><span className="font-bold">Marked for Review:</span> {metadata.review || 'False'}</span>
          <span className="truncate"><span className="font-bold">Marked for Fleetedge:</span> {metadata.fleetedge || 'False'}</span>
        </div>
      </div>
      <div className="flex flex-col justify-start space-y-2">
        <div className="flex flex-row justify-start space-x-2 h-fit">
          <button
            className="px-2 py-1 bg-white text-black w-fit rounded-md hover:cursor-pointer hover:opacity-80"
            onClick={() => updateVideoMetadata('Liked')}
            disabled={isLoading}
          >
            {metadata.liked === 'True' ? 'Unlike Video' : 'Like Video'}
          </button>
          <button
            className="px-2 py-1 bg-white text-black w-fit rounded-md hover:cursor-pointer hover:opacity-80"
            onClick={() => updateVideoMetadata('Review')}
            disabled={isLoading}
          >
            {metadata.review === 'True' ? 'Unmark For Review' : 'Mark For Review'}
          </button>
        </div>
        <button
          className="px-2 py-1 self-start bg-white text-black w-fit rounded-md hover:cursor-pointer hover:opacity-80"
          onClick={() => updateVideoMetadata('Fleetedge')}
          disabled={isLoading}
        >
          {metadata.fleetedge === 'True' ? 'Unmark for Fleetedge Issue' : 'Mark For Fleetedge Issue'}
        </button>
      </div>
    </div>
  </div>
);

export default MetadataInfo;
