import React, { useState } from 'react';
import formatDateTime from '../../utils/formatDateTime';
import CSVDownloader from '../CSVDownloader';

interface VideoGridProps {
  videos: any[];
  onSelectVideo: (id: any) => void;
  setNewestToOldest: React.Dispatch<React.SetStateAction<boolean>>;
  newestToOldest: boolean;
  missingVideos: number;
}

const getMetadataValue = (metadata: { key: string; value: string }[], key: string): string | undefined => {
  const item = metadata.find(meta => meta.key === key);
  return item ? item.value : undefined;
};

const VideoGrid: React.FC<VideoGridProps> = ({ videos, onSelectVideo, setNewestToOldest, newestToOldest, missingVideos }) => {
  const [gridSize5, setGridSize5] = useState(false);

  return (
    <div className="w-full h-full mt-10">
      {/* Control Section */}
      <div className="flex flex-col lg:flex-row justify-between items-center mb-6 p-4 rounded-md shadow-lg bg-white">
        <div className="flex space-x-4 items-center mb-4 lg:mb-0">
          {/* Grid Size and Sort Buttons */}
          <div className="flex space-x-3">
            <button 
              onClick={() => setGridSize5(!gridSize5)} 
              className="px-3 py-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md transition"
            >
              {gridSize5 ? `3 Column View` : `5 Column View`}
            </button>
            <button 
              onClick={() => setNewestToOldest(!newestToOldest)} 
              className="px-3 py-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md transition"
            >
              {newestToOldest ? `A-Z` : `Z-A`}
            </button>
          </div>
          {/* Video Count */}
          <span className="font-bold text-gray-700">{videos.length} results found</span>
        </div>

        {/* CSV Download Button */}
        <CSVDownloader videos={videos} />
      </div>

      {/* Video Grid */}
      <div className={`grid ${gridSize5 ? 'grid-cols-5' : 'grid-cols-3'} gap-4 p-4`}>
        {videos.map((video) => (
          <div
            onClick={() => onSelectVideo(video)}
            key={video.videoId}
            className="hover:opacity-90 cursor-pointer transition duration-200 ease-in-out transform hover:scale-105 bg-white shadow-md border border-gray-200"
          >
            {/* Video Thumbnail */}
            <div className="relative">
              <h3 className={`absolute text-white bg-gray-800 px-4 py-1 font-bold ${gridSize5 ? 'text-xs' : 'text-lg'}`}>
                {formatDateTime(getMetadataValue(video.metadata, 'start_time') || '')}
              </h3>
              <img src={video.assets.thumbnail} alt={video.videoId} className="w-full h-auto" />
            </div>

            {/* Video Metadata */}
            <div className={`p-2 bg-gray-800 text-white ${gridSize5 ? 'text-sm' : 'text-lg'}`}>
              <div className="flex flex-col 2xl:flex-row justify-between">
                <h3 className="font-semibold">{getMetadataValue(video.metadata, 'device_id')}</h3>
                <h3 className="text-xs self-center">UTC: {getMetadataValue(video.metadata, 'start_time')}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGrid;
