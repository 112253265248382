import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Home from './pages/DashboardHome';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Videos from './pages/Videos';
import ProtectedRoute from './components/Navigation/ProtectedRoute';
import VideoPlayer from './pages/VideoPlayer';
import Bunny from './pages/Bunny';

const App: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.auth);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          {/* Redirect root to dashboard or login based on auth */}
          <Route path="/" element={token ? <Navigate to="/dashboard/home" /> : <Navigate to="/login" />} />
          
          {/* Login Route */}
          <Route path="/login" element={<Login />} />

          {/* Protected dashboard routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            {/* Dashboard subroutes */}
            <Route path="home" element={<Home />} />
            <Route path="videos" element={<Videos />} />
            <Route path="bunny" element={<Bunny />} />
          </Route>

          {/* Separate route for VideoPlayer */}
          <Route
            path="/video/:videoId"
            element={
              <ProtectedRoute>
                <VideoPlayer />
              </ProtectedRoute>
            }
          />

          {/* Redirect any unknown routes to the login page */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
};

export default App;
