import React, { useEffect, useState } from 'react';

interface CommentSectionProps {
  comments: string;
  addComment: (comment: string) => Promise<void>;
  handleDeleteComment: (indexToDelete: number) => Promise<void>;
  isLoading: boolean;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  newComment: string;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  comments,
  addComment,
  handleDeleteComment,
  isLoading,
  setNewComment,
  newComment,
}) => {
  const [commentList, setCommentList] = useState<string[]>([]);

  useEffect(() => {
    if (comments) {
      const commentsArray = JSON.parse(comments);
      setCommentList(commentsArray);
    }
  }, [comments]);

  return (
    <div className='w-full bg-gray-800 p-6 rounded-none shadow-lg m-2'>
      <h3 className='text-white text-sm mb-4'>Comments</h3>
      <div className='flex flex-col space-y-3'>
        {commentList && commentList.length > 0 ? (
          commentList.map((comment, index) => (
            <div
              key={index}
              className='bg-gray-700 p-3 rounded-md text-white flex justify-between items-center shadow-md'
            >
              <span>{comment}</span>
              <button
                className='text-red-500 text-2xl hover:text-red-700'
                onClick={() => handleDeleteComment(index)}
              >
                &times;
              </button>
            </div>
          ))
        ) : (
          <div className='text-xs text-gray-300'>No comments yet. Be the first to comment!</div>
        )}
      </div>
      <div className='flex flex-row space-x-4 mt-6'>
        <input
          className='w-full p-2 text-xs rounded-md border border-gray-500 bg-gray-600 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
          type='text'
          placeholder='Add a comment...'
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button
          className={`bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-sm font-bold py-2 px-4 rounded-md shadow-lg hover:from-blue-600 hover:to-indigo-700 transition-all ease-in-out duration-300 ${
            isLoading ? 'cursor-not-allowed bg-gray-500' : ''
          }`}
          onClick={() => addComment(newComment)}
          disabled={isLoading}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CommentSection;
