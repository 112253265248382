const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function formatDateTime(dateTimeString: string) {
    // Parse the UTC date-time string using the specified format
    const dateTime = dayjs.utc(dateTimeString, 'MM-DD-YYYYTHH:mm:ss');

    // Check if the date is valid
    if (!dateTime.isValid()) {
        return "Invalid Date";
    }

    // Convert the date-time to EST (America/Toronto)
    const estDateTime = dateTime.tz("America/Toronto");

    // Format the date-time into a more readable format
    return estDateTime.format('MMMM DD, YYYY hh:mm A');
}

export default formatDateTime;