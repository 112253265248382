import React from 'react';


interface SubmitButtonProps {
    isLoading: boolean;
    isValid: boolean;
    label: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({isLoading, isValid, label}) => {
  return (
    <div className="flex items-center justify-between mt-[30px]">
      <button
        disabled={isLoading || !isValid}
        className={(isLoading || !isValid) ? `bg-gray-300 text-white font-bold py-2 px-4 rounded` : `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
      >
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;