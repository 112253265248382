// components/VideoPlayerHandler.tsx
import React, { useRef } from 'react';
import Player, { ApiVideoPlayerRef } from '@api.video/react-player';

interface VideoPlayerHandlerProps {
  videoId: string;
  startTime: number;
  handleTimeUpdate: (time: number) => void;
}

const VideoPlayerHandler: React.FC<VideoPlayerHandlerProps> = ({ videoId, startTime, handleTimeUpdate }) => {
  const playerRef = useRef<ApiVideoPlayerRef>(null);

  return (
    <Player
      ref={playerRef}
      video={{ id: videoId }}
      style={{ width: '960px', height: '590px' }}
      onReady={() => playerRef.current?.seek(startTime)}
      onTimeUpdate={handleTimeUpdate}
    />
  );
};

export default VideoPlayerHandler;
