// components/Spinner.tsx
import React from 'react';

interface SpinnerProps {
  size?: number; // Optional prop to control size of the spinner
  color?: string; // Optional prop to control color of the spinner
}

const Spinner: React.FC<SpinnerProps> = ({ size = 24, color = 'white' }) => {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke={color}
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill={color}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
      ></path>
    </svg>
  );
};

export default Spinner;
