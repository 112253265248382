import React from 'react';
import { Metadata } from '../../types';
import DownloadButton from './DownloadVideoButton';

interface VideoActionsProps {
  metadata: Metadata;
  videoId: string;
  copied: boolean;
  setCopied: (value: boolean) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  currentTime: number;
}

const VideoActions: React.FC<VideoActionsProps> = ({
  metadata,
  videoId,
  copied,
  setCopied,
  setIsLoading,
  isLoading,
  currentTime
}) => {
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  };

  const handleOnCopy = () => {
    const updatedUrl = `${window.location.origin}${window.location.pathname}?time=${Math.floor(currentTime)}`;
    unsecuredCopyToClipboard(updatedUrl);
    setCopied(true);
  };

  return (
    <div className='w-fit bg-gray-800 my-2 p-4 rounded-none'>
      <div className="flex flex-col justify-between items-center space-y-2">
        <DownloadButton
          vehicle={metadata.device_id}
          startTime={metadata.start_time}
          videoId={videoId!}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <button
          className="w-full text-xs relative bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 font-bold py-2 px-4 rounded-md shadow-lg transition-all ease-in-out duration-300"
          onClick={handleOnCopy}
        >
          Copy Link
        </button>
        {copied && <span className="text-green-500 font-bold">Copied!</span>}
      </div>
      {/* {isLoading && (
        <div className="flex justify-center mt-2">
          <img
            height="40"
            width="40"
            src={'https://media.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif'}
            alt="Loading..."
          />
        </div>
      )} */}
    </div>
  );
};

export default VideoActions;
