import React from 'react';

const KibanaLinkButton = () => {
    return (
        <div className='h-fit w-[150px] text-center text-sm'>
            What days are available?
            <button
                className='mt-3 h-fit w-[150px] bg-blue-500 rounded-md text-white text-xs px-3 py-2'
                onClick={() => window.open('http://216.153.60.37/app/dashboards#/view/f54a59a0-97b5-11ef-a154-3f517a58caa7?_g=(filters:!(),query:(language:kuery,query:""),refreshInterval:(pause:!f,value:10000),time:(from:now-7d%2Fd,to:now))', '_blank')}
            >
                View bunny data collection uptime
            </button>
        </div>
    );
};

export default KibanaLinkButton;
