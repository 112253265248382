import axios from 'axios';

interface SendDataProps {
  data: string;
  endpoint: string;
}

const sendData = async ({ data, endpoint }: SendDataProps): Promise<any> => {
  try {
    // Send request with credentials (cookies) and without manual token handling
    const response = await axios.post<any>(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, { data }, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,  // Ensure cookies (including the JWT) are sent with the request
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const submitFormData = async (data: any, endpoint: string): Promise<any> => {
  try {
    // Serialize the data before sending
    const response = await sendData({ data: JSON.stringify(data), endpoint });
    return response;
  } catch (error) {
    console.error("Submission failed", error);
    throw error;
  }
};

export default submitFormData;
