import React, { useState, SetStateAction, Dispatch, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import FormWrapper from './FormWrapper';
import SelectDropdown from './SelectDropdown';
import SubmitButton from './SubmitButton';
import { SelectChangeEvent } from '@mui/material/Select';
import submitFormData from '../../utils/submitFormData';
import { FormData } from '../../pages/Videos';
import BunnyDateTimePicker from './BunnyDateTimePicker';

interface BunnyFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  vehicles: string[];
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formData: FormData;
}

const BunnyCsvForm: React.FC<BunnyFormProps> = ({ setIsLoading, isLoading, vehicles, setFormData, formData }) => {
  const now = dayjs().tz("America/Toronto");
  const midnight = now.startOf('day');
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  const handleDateChange = (field: keyof FormData) => (value: dayjs.Dayjs | null) => {
    setFormData({ ...formData, [field]: value ? value.toISOString() : '' });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, vehicle: [event.target.value as string] });
  };

  function checkIsValid() {
    return formData.from !== '' && formData.to !== '';
  }

  useEffect(() => {
    const valid = checkIsValid();
    setIsValid(valid);
  }, [formData]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setIsLoading(true);
      console.log('Submitting form data:', formData);

      const response = await submitFormData(
        { device_id: formData.vehicle[0], date_str: formData.from },
        'bunny-csv'
      );

      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bunny.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log('Csv file downloaded');
      } else {
        console.error('No response received');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 401) {
          alert('Session has expired, please login again.');
          navigate('/login');
        }
      } else {
        console.error('Submission failed', error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <BunnyDateTimePicker
        defaultValue={midnight}
        label="Route Day"
        value={formData.from}
        onChange={handleDateChange('from')}
      />
      <SelectDropdown
        title="Vehicle"
        value={formData.vehicle[0]}
        onChange={handleSelectChange}
        options={vehicles}
      />
      <SubmitButton label="Submit" isValid={isValid} isLoading={isLoading} />
    </FormWrapper>
  );
};

export default BunnyCsvForm;
