import React, { useEffect, useState } from 'react';
import DataForm from '../components/Forms/DataForm';
import VideoGrid from '../components/Video/VideoGrid';
import { parse } from 'date-fns';
import DeviceStatusIndicator from '../components/Socketxp/DeviceStatusIndicator';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


const getMetadataValue = (metadata: { key: string; value: string }[], key: string): string | undefined => {
  const item = metadata.find(meta => meta.key === key);
  return item ? item.value : undefined;
};

export interface FormData {
  from: string;
  to: string;
  vehicle: string[];
  filterBy: string;
  generateCsvs: boolean;
}

const Videos: React.FC = () => {
  const [videoSourceList, setVideoSourceList] = useState<any[]>([]);
  const [coverage, setCoverage] = useState<any[]>([]);
  const [sortedVideoSourceList, setSortedVideoSourceList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isQueryEmpty, setIsQueryEmpty] = useState(false);
  const [vehicles, setVehicles] = useState(['All', 'Pilot2_BFLG20', 'Pilot2_BFLG29', 'Pilot2_BFLG38', 'Pilot2_BFLG39', 'Pilot2_BFLG40','Pilot2_BFLG41','Pilot1_CM'])
  const [newestToOldest, setNewestToOldest] = useState(true);
  const [missingVideos, setMissingVideos] = useState(0);

  const now = dayjs().tz("America/Toronto");
  const midnight = now.startOf('day');

  const [formData, setFormData] = useState<FormData>({
    from: midnight.toISOString(),
    to: now.toISOString(),
    vehicle: ['All'],
    filterBy: 'All',
    generateCsvs: false,
  });

  useEffect(() => {
    // Sort videoSourceList by start_time using date-fns
    const sortedVideos = [...videoSourceList].sort((a, b) => {
      const aStartTime = getMetadataValue(newestToOldest ? a.metadata : b.metadata, 'start_time') || '';
      const bStartTime = getMetadataValue(newestToOldest ? b.metadata : a.metadata, 'start_time') || '';

      // Parse the date strings
      const aDate = parse(aStartTime, 'MM-dd-yyyy\'T\'HH:mm:ss', new Date());
      const bDate = parse(bStartTime, 'MM-dd-yyyy\'T\'HH:mm:ss', new Date());

      return bDate.getTime() - aDate.getTime();
    });

    setSortedVideoSourceList(sortedVideos);
  }, [videoSourceList, newestToOldest]);

  const handleSelectVideo = (video: any) => {
    // Open a new window/tab for the video
    window.open(`/video/${video.videoId}`, '_blank');
  };
  
  return (
    <div className="p-4 flex flex-col h-full w-full space-y-4">
      <div className='w-full flex flex-row justify-center space-x-4 my-5'>
        <div className='flex flex-col justify-start w-fit'>
          <DataForm coverage={coverage} setCoverage={setCoverage} vehicles={vehicles} setIsQueryEmpty={setIsQueryEmpty} setVideos={setVideoSourceList} setIsLoading={setIsLoading} isLoading={isLoading} setFormData={setFormData} formData={formData} />
        </div>
        <DeviceStatusIndicator setFormData={setFormData} formData={formData} setVehicles={setVehicles} />
      </div>
      {sortedVideoSourceList.length > 0 && !isLoading && (
        <VideoGrid missingVideos={missingVideos} videos={sortedVideoSourceList} onSelectVideo={handleSelectVideo} setNewestToOldest={setNewestToOldest} newestToOldest={newestToOldest} />
      )}
      {isLoading && (
        <div className='w-full flex justify-center pt-10'>
          <img height="40" width="40" src={'https://media.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif'} alt="Loading..." />
        </div>
      )}
      {!isLoading && isQueryEmpty && (
        <div className='w-full flex justify-center h-full p-5 space-x-3'>
          <span className='text-sm text-red-600'>{`No videos match the requirements.`}</span>
        </div>
      )}
    </div>
  );
};

export default Videos;