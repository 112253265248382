import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import Spinner from '../Spinner';

interface LogWindowProps {
  logMessages: { msgBody: string; timestamp: string; ip: string }[];
  currentTimeInSeconds: number;
  videoStartTime: string;
  isLoading: boolean;
}

const LogWindow: React.FC<LogWindowProps> = ({ logMessages, currentTimeInSeconds, videoStartTime, isLoading }) => {
  const logWindowRef = useRef<HTMLDivElement>(null);
  const [visibleMessages, setVisibleMessages] = useState<{ msgBody: string; ip: string; timestamp: string }[]>([]);
  const [activeTab, setActiveTab] = useState<'left' | 'right'>('left'); // State to control which tab is active

  useEffect(() => {
    if (logWindowRef.current) {
      logWindowRef.current.scrollTop = logWindowRef.current.scrollHeight;
    }
  }, [visibleMessages]);

  useEffect(() => {
    if (logMessages) {
      const videoStartDate = dayjs(videoStartTime, 'MM-DD-YYYYTHH:mm:ss');
      if (!videoStartDate.isValid()) {
        console.error('Invalid or missing video start time:', videoStartTime);
      }
      const videoStartTimeMs = videoStartDate.toDate().getTime();
      const currentAbsoluteTime = videoStartTimeMs + currentTimeInSeconds * 1000 - 4 * 60 * 60 * 1000; // Adjust for timezones
      const filteredMessages = logMessages.filter(({ timestamp }) => {
        const messageTime = new Date(timestamp).getTime();
        return messageTime <= currentAbsoluteTime;
      });
      setVisibleMessages(filteredMessages);
    }
  }, [logMessages, currentTimeInSeconds, videoStartTime]);

  // Helper function to filter messages by the IP suffix
  const getFilteredMessagesByTab = (tab: 'left' | 'right') => {
    return visibleMessages.filter(({ ip }) => {
      try {
        if (!ip) {
          console.error(`Invalid IP address found: ${ip}`);
          return false; // Skip invalid IP addresses
        }
        if (tab === 'left') {
          return ip.endsWith('.21');
        }
        return ip.endsWith('.22');
      } catch (error) {
        console.error(`Error processing IP address ${ip}:`, error);
        return false; // Skip this entry if there's an error
      }
    });
  };

  return (
    <div className="flex flex-col h-full bg-gray-800 text-white shadow-lg rounded-lg">
      {/* Tabs */}
      <div className="flex flex-row justify-start bg-gray-800 p-4">
        <button
          className={`px-2 py-1 text-white text-sm ${
            activeTab === 'left' ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-500'
          } rounded-l`}
          onClick={() => setActiveTab('left')}
        >
          Left Projector
        </button>
        <button
          className={`px-2 py-1 text-white text-sm ${
            activeTab === 'right' ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-500'
          } rounded-r`}
          onClick={() => setActiveTab('right')}
        >
          Right Projector
        </button>
      </div>
      <div
        ref={logWindowRef}
        className="log-window bg-gray-800 text-white font-mono text-xs overflow-y-scroll p-4 h-[530px] min-w-[350px] max-w-[350px]"
      >
        {isLoading ? (
          // Show spinner when loading
          <div className="flex items-center justify-center h-full">
            <Spinner size={40} color="white" />
          </div>
        ) : (
          // Show logs when not loading
          logMessages.length === 0 ? (
            <div className="text-gray-400 italic">No logs found at this timestamp.</div>
          ) : (
            getFilteredMessagesByTab(activeTab).map(({ msgBody, timestamp }, index) => (
              <div key={index} className="log-message mb-2">
                <span className="font-bold text-green-400">{dayjs(timestamp).format('HH:mm:ss')}</span>
                <span className="text-gray-300">: {msgBody}</span>
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
};

export default LogWindow;
