import React from 'react';

interface FormWrapperProps {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent) => void;
}

const FormWrapper: React.FC<FormWrapperProps> = ({ children, onSubmit }) => {
  return (
    <div className="w-[400px]">
      <form onSubmit={onSubmit} className="p-4 border rounded-lg shadow-md w-[360px] h-fit bg-white">
        {children}
      </form>
    </div>
  );
};

export default FormWrapper;